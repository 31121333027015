@tailwind base
@tailwind components;
@tailwind utilities;

@import './node_modules/react-perfect-scrollbar/dist/css/styles';
@import 'primereact/resources/themes/tailwind-light/theme.css';
@import 'primeicons/primeicons.css';

@import 'variables';
@import 'slick';
@import 'time';

th,
td {
  font-family: Quicksand !important;
}

th {
  color: #32312f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

// td {
//   color: #32312f;
//   padding: 1rem 1.5rem !important;
//   /* H4 */
//   font-family: Quicksand;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 150%; /* 21px */
// }

.p-datatable-table {
  svg {
    width: 12px !important;
  }
}

button, span, div {
  font-family: Quicksand !important;
}

.p-paginator {
  gap: 0.5rem;

  .p-paginator-pages {
    display: flex;
    gap: 0.5rem;
  }

  .p-paginator-page {
    &.p-highlight {
      background-color: #e26247 !important;
      border-color: #e26247 !important;
      color: #fff !important;
    }
  }

  button {
    border-radius: 100%;
    font-size: 14px;
    min-width: 2rem;
    height: 2rem;
  }

  .p-dropdown {
    height: 2rem;
    .p-dropdown-label {
      font-size: 14px;
      padding: 8px 0 0 8px;
    }
  }
}

.p-card {
  border-radius: 1rem !important;
  overflow: hidden;
}

.p-calendar {
  border-radius: 12px;
  border: 1px solid #DFE0E1;
  overflow: hidden;

  .p-inputtext {
    font-family: Quicksand !important;
    background: #FFF;
    border: 0;
  }

  .p-button-icon-only {
    background: transparent;
    color: #3f3f46;
    border: 0;
  }
}

.horizontal-line {
  border-bottom: 1px solid #DFE0E1;
}

.bg-white {
  background-color: #fff;
}

.no-underline {
  text-decoration: none !important;
}

.border-gray-400 {
  border: 1px solid #DFE0E1 !important;
}

.cursor-pointer {
  cursor: pointer;
}


.react-modal-sheet-container {
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}

.react-modal-sheet-header {
  padding: 16px !important;
  border-bottom: 1px solid grey;
}
.react-modal-sheet-content {
  padding: 18px 16px !important;
}

.min-width-35 {
  width: 35px;
  height: 35px;
}

.mobile-info-view-group {
  display: block;
}
.mobile-info-view-title,
.mobile-info-view-title-bold {
  width: 150px;
  color: #374151;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 4px;
}

.mobile-info-view-title-bold {
  font-size: 14px;
}

.mobile-info-view-content {
  font-weight: 500;
  font-size: 14px;
}

//FULL CALENDAR

.fc {
  &.fc-theme-standard {
    height: 100%;
  }

  .fc-button-group {
    gap: 3px;
  }
}

.fc-button-primary {
  background-color: #e26247 !important;
  border-color: #e26247 !important;
  text-transform: capitalize !important;
  border-radius: 0.25em !important;

  &.fc-button-active {
    box-shadow: #e26247;
  }
}

.fc-col-header-cell {
  padding: 10px !important;
}

.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
}

.fc-col-header-cell {
  background-color: rgb(235, 237, 241) !important;
}

.fc-toolbar-title::first-letter {
  text-transform: capitalize !important;
}

.fc-day-today {
  background-color: inherit !important;
}

.fc .fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-top .fc-daygrid-day-number {
  background-color: #e26247;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.fc-day-sat,
.fc-day-sun {
  background-color: rgb(249,249,249);
}

.fc-button-group {
  gap: 0 !important;
}
.fc-next-button,
.fc-prev-button {
  background-color: white !important;
  border-color: lightgray !important;
}

.fc-prev-button .fc-icon,
.fc-next-button .fc-icon {
  color: grey;
}

.p-inputtext:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus {
  border-color: #e26247 !important;
  box-shadow: 0 0 0 0.02rem white !important;
}

.mantine-Input-wrapper {
  margin-top: 0px;
}

.mantine-TextInput-label,
.mantine-PasswordInput-label,
.mantine-NumberInput-label,
.mantine-DatePicker-label,
.mantine-Select-label,
.mantine-Textarea-label,
.mantine-InputWrapper-label,
.mantine-Checkbox-label {
  @media (max-width: 992px) {
    font-size: 12px;
  }
}

.mantine-TextInput-input,
.mantine-PasswordInput-input,
.mantine-NumberInput-input,
.mantine-DatePicker-input,
.mantine-TimeInput-timeInput,
.mantine-Select-input,
.mantine-Textarea-input,
.mantine-InputWrapper-input,
.mantine-PasswordInput-innerInput,
.mantine-Badge-inner {
  @media (max-width: 992px) {
    font-size: 14px !important;
  }
}

.mantine-InputWrapper-label {
  @media (max-width: 992px) {
    margin-bottom: 4px !important;
  }
}

.mantine-SegmentedControl-root {
  overflow: unset;
}
.mantine-Dropzone-root {
  border-color: #e26247;
}

.mantine-Modal-modal-padding-top {
  @media (max-width: 992px) {
    padding-top: 60px !important;
  }
}
.mantine-Modal-modal-padding {
  @media (max-width: 992px) {
    padding: 24px 0px !important;
    padding-top: 60px !important;
  }
}
.mantine-Modal-header {
  @media (max-width: 992px) {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
}
.mantine-Modal-modal {
  @media (max-width: 992px) {
    min-width: unset;
    padding: 0px !important;
  }
}

.mantine-Modal-title {
  font-size: 24px;
  @media (max-width: 992px) {
    width: 100%;
    margin-right: 0px;
  }
}

.mantine-Col-root {
  @media (max-width: 992px) {
    padding: 0px !important;
  }
}

.mantine-Grid-root {
  @media (max-width: 992px) {
    padding: 4px 8px !important;
  }

}

.form-error {
  color: #ef4444;
  font-size: 14px;
  line-height: 1.2;
}

.mantine-Calendar-calendarBase {
  @media (max-width: 992px) {
    min-width: 100% !important;
  }
}
.mantine-Calendar-month {
  @media (max-width: 992px) {
    width: 100% !important;
  }
}
.mantine-Calendar-weekday,
.mantine-Calendar-day {
  @media (max-width: 992px) {
    font-size: 14px !important;
    text-transform: uppercase !important;
  }
}

.mantine-Calendar-weekday {
  @media (max-width: 992px) {
    color: black;
  }
}
.mantine-Calendar-calendarHeader {
  @media (max-width: 992px) {
    height: 36px;
  }
}

/*.mantine-Calendar-calendarHeader {
  @media (max-width: 992px) {
    visibility: hidden;
    height: 0px;
  }
}*/

.mantine-Calendar-calendarHeaderLevel {
  @media (max-width: 992px) {
    height: 36px;
    font-weight: bold;
    font-size: 24px;
  }
}

.mantine-Calendar-calendarHeaderControl {
  @media (max-width: 992px) {
    height: 36px;
    font-weight: bold;
    font-size: 24px;
    border: 1px solid lightgray;
    border-radius: 8px;
  }
}

.mantine-Modal-header {
  @media (max-width: 992px) {
    margin: 0px;
  }
}

.mantine-Modal-body {
  @media (max-width: 992px) {
    //height: 100%;
  }
}


.ElementsApp input {
  font-family: Quicksand !important;
  font-size: 14px;
  color: #000;
}

.stripe-manual-form {
  padding: 12px 16px;
}

.mantine-Tabs-tab {
  padding: 10px 16px;
  @media (max-width: 992px) {
    padding: 10px 4px;
  }
}

.mantine-Tooltip-tooltip {
  padding: 16px;
  font-size: 14px;
  max-width: 500px;

  text-align: justify;
  @media (max-width: 992px) {
    max-width: unset;
  }
}


.react-tel-input {

}
.react-tel-input .form-control {
  width: 100%;
  padding: 12px 14px 12px 60px;
}
.react-tel-input .form-control:focus {
  border: 1px solid #e26247;
  outline: none 0;
  box-shadow: none;
}

.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border-color: transparent !important;
}

.phone-widget-title {
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  color: #212529;
  @media (max-width: 992px) {
    font-size: 16px;
    font-weight: bold;
  }
}

.phone-widget-error {
  padding-top:4px;
  color: #ef4444;
  font-size: 18px;
  line-height: 1.2;
  @media (max-width: 992px) {
    font-size: 16px;
  }
}

.mantine-Header-root {
  padding: 0 10px;
}

.hover-mask {
  background-color: white;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.sticky-header {
  position: fixed;
}

.z-index {
  z-index: 1;
}
.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.sticky-footer-desktop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding-right: 16px !important;
}

.max-vh {
  height: 100vh;
}

.scroll-popup {
  width: 100%;
  height: 65vh;
  overflow: auto;
}

#preview .preview-placeholder {
  font-size: 18px;
}

.add-new-item {
  @media (max-width: 992px) {
    font-size: 14px;
  } 
}


.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-slider {
  width:100% !important
}

.slick-dots {
  display:flex!important;
  position:static!important;
  margin-top:24px!important;
  justify-content:center;
  gap:8px;
}

.slick-dots li {
  margin:0!important;
  padding:0!important;
  width:auto!important;
  height:auto!important
}
.slick-dots div {
  background-color:#a4acb0;
  width:10px;
  height:10px;
  border-radius:50%;
  opacity:.3
}
.slick-dots .slick-active div {
  background-color:#02334b;
  width:10px;
  height:10px;
  opacity:1
}
.slick-next:before,
.slick-prev:before {
  content:""!important
}
.slick-dots {
  margin:44px 0 0!important
}

.slick-next,
.slick-prev {
  font-size:0;
  line-height:0;
  position:absolute;
  top:50%;
  display:block;
  width:20px;
  height:20px;
  padding:0;
  transform:translateY(-50%);
  cursor:pointer;
  border:none
}
.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  color:transparent;
  outline:none;
  background:transparent
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity:1
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity:.25
}
.slick-next:before,
.slick-prev:before {
  font-family:slick;
  font-size:20px;
  line-height:1;
  opacity:.75;
  color:#fff;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
.slick-prev {
  left:-25px
}
[dir=rtl] .slick-prev {
  right:-25px;
  left:auto
}
.slick-prev:before {
  content:"←"
}
[dir=rtl] .slick-prev:before {
  content:"→"
}
.slick-next {
  right:-25px
}
[dir=rtl] .slick-next {
  right:auto;
  left:-25px
}
.slick-next:before {
  content:"→"
}
[dir=rtl] .slick-next:before {
  content:"←"
}
.slick-dotted.slick-slider {
  margin-bottom:30px
}
.slick-dots {
  position:absolute;
  bottom:-25px;
  display:block;
  width:100%;
  padding:0;
  margin:0;
  list-style:none;
  text-align:center
}
.slick-dots li {
  position:relative;
  display:inline-block;
  margin:0 5px;
  padding:0
}
.slick-dots li,
.slick-dots li button {
  width:20px;
  height:20px;
  cursor:pointer
}
.slick-dots li button {
  font-size:0;
  line-height:0;
  display:block;
  padding:5px;
  color:transparent;
  border:0;
  outline:none;
  background:transparent
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline:none
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity:1
}
.slick-dots li button:before {
  font-family:slick;
  font-size:6px;
  line-height:20px;
  position:absolute;
  top:0;
  left:0;
  width:20px;
  height:20px;
  content:"•";
  text-align:center;
  opacity:.25;
  color:#000;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
.slick-dots li.slick-active button:before {
  opacity:.75;
  color:#000
}
.slick-slider {
  box-sizing:border-box;
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none;
  -webkit-touch-callout:none;
  -khtml-user-select:none;
  touch-action:pan-y;
  -webkit-tap-highlight-color:transparent
}
.slick-list,
.slick-slider {
  position:relative;
  display:block
}
.slick-list {
  overflow:hidden;
  margin:0;
  padding:0;
  max-width: 900px !important;
}
.slick-list:focus {
  outline:none
}
.slick-list.dragging {
  cursor:pointer;
  cursor:hand
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform:translateZ(0)
}
.slick-track {
  position:relative;
  top:0;
  left:0;
  display:block;
  margin-left:auto;
  margin-right:auto
}
.slick-track:after,
.slick-track:before {
  display:table;
  content:""
}
.slick-track:after {
  clear:both
}
.slick-loading .slick-track {
  visibility:hidden
}
.slick-slide {
  display:none;
  float:left;
  height:100%;
  min-height:1px
}
[dir=rtl] .slick-slide {
  float:right
}
.slick-slide img {
  display:block
}
.slick-slide.slick-loading img {
  display:none
}
.slick-slide.dragging img {
  pointer-events:none
}
.slick-initialized .slick-slide {
  display:block
}
.slick-loading .slick-slide {
  visibility:hidden
}
.slick-vertical .slick-slide {
  display:block;
  height:auto;
  border:1px solid transparent
}
.slick-arrow.slick-hidden {
  display:none
}

.p-AccordionItem.p-AccordionItem,
.AccordionItem {
  padding: 0 !important;
}

.text-bold {
  font-weight: bold;
}

.support-border {
  border: 2px solid #e26247;
  border-radius: 50%;
  padding: 4px;
  background: white;
  display:flex;
  align-items: center;
}

.align-center {
  align-items: center;
}
.align-end {
  align-items: end;
}

.my-time-input {
  width: 100%;
  height: 50px;
  padding: 16px;
  font-family: 'Quicksand';
  font-size: 18px;
  border-radius: 8px;
  background-color: 'white';
  border: 1px solid #ced4da;
  @media (max-width: 992px) {
    height: 42px;
  }
}

.my-time-input:focus {
  border: 1px solid #e26247;
  outline: none;
}

.my-time-invalid {
  border: 2px solid #ef4444;
  color: #ef4444;
}
.my-time-error {
    font-family: Quicksand;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: 1.55;
    -webkit-text-decoration: none;
    text-decoration: none;
    word-break: break-word;
    color: #ef4444;
    font-size: 14px;
    line-height: 1.2;
    display: block;
}